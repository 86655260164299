@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;600&display=swap');

* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
}



:root {
    --theme-color: #78EB54;
    --theme-color-2: #45A735;
    --secondary-color: #26472B;
    --text-color: #666666;
    --title-color: #111111;
    --text-font: "Inter", sans-serif;
    --title-font: "Outfit", sans-serif;
}





body {
    font-size: 16px;
    color: var(--text-color);
    line-height: 26px;
    font-weight: 400;
    background: #ffffff;
    font-family: var(--text-font);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    -webkit-font-smoothing: antialiased;
}

@media (min-width:1200px) {
    .container {
        max-width: 1200px;
        padding: 0px 15px;
    }
}

.large-container {
    max-width: 1680px;
    padding: 0px 15px;
    margin: 0 auto;
}

.container-fluid {
    padding: 0px;
}

.auto-container {
    position: static;
    max-width: 1320px;
    padding: 0px 15px;
    margin: 0 auto;
}

.small-container {
    max-width: 680px;
    margin: 0 auto;
}

.boxed_wrapper {
    position: relative;
    margin: 0 auto;
    overflow: hidden !important;
    width: 100%;
    min-width: 300px;
}


a {
    text-decoration: none;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

a:hover {
    text-decoration: none;
    outline: none;
}

input,
button,
select,
textarea {
    font-family: var(--text-font);
    font-weight: 400;
    font-size: 18px;
    background: transparent;
}

::-webkit-input-placeholder {
    color: inherit;
}

::-moz-input-placeholder {
    color: inherit;
}

::-ms-input-placeholder {
    color: inherit;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

input {
    transition: all 500ms ease;
}

button:focus,
input:focus,
textarea:focus {
    outline: none;
    box-shadow: none;
    transition: all 500ms ease;
}

p {
    position: relative;
    font-family: var(--text-font);
    color: var(--text-color);
    font-weight: 400;
    margin: 0px;
    transition: all 500ms ease;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    font-family: var(--title-font);
    font-weight: 600;
    /* color: var(--title-color); */
    margin: 0px;
    transition: all 500ms ease;
}

.mobileHide {
    display: inline;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .mobileHide {
        display: none;
    }
}

.mobileShow {
    display: none;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .mobileShow {
        display: inline;
    }
}

/* Preloader */

.handle-preloader {
    align-items: center;
    -webkit-align-items: center;
    display: flex;
    display: -ms-flexbox;
    height: 100%;
    justify-content: center;
    -webkit-justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9999999;
    background: #2671A9;
}

.preloader-close {
    position: fixed;
    z-index: 99999999;
    font-size: 12px;
    background: #fff;
    color: var(--theme-color);
    width: 40px;
    height: 40px;
    line-height: 42px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    right: 30px;
    top: 30px;
    font-weight: 400;
}

.handle-preloader .animation-preloader {
    position: absolute;
    z-index: 100;
}

.handle-preloader .animation-preloader .spinner {
    animation: spinner 1s infinite linear;
    border-radius: 50%;
    height: 150px;
    margin: 0 auto 45px auto;
    width: 150px;
}

.handle-preloader .animation-preloader .txt-loading {
    text-align: center;
    user-select: none;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
    animation: letters-loading 4s infinite;
    content: attr(data-text-preloader);
    left: 0;
    opacity: 0;
    top: 0;
    position: absolute;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading {
    font-family: var(--title-font);
    font-weight: 500;
    letter-spacing: 15px;
    display: inline-block;
    position: relative;
    font-size: 70px;
    line-height: 70px;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.30);
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
    animation-delay: 0.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
    animation-delay: 0.4s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
    animation-delay: 0.6s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
    animation-delay: 0.8s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
    animation-delay: 1s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
    animation-delay: 1.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
    animation-delay: 1.4s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(9):before {
    animation-delay: 1.6s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(10):before {
    animation-delay: 1.8s;
}

.handle-preloader .loader-section {
    background-color: #ffffff;
    height: 100%;
    position: fixed;
    top: 0;
    width: calc(50% + 1px);
}

.preloader .loaded .animation-preloader {
    opacity: 0;
    transition: 0.3s ease-out;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
    color: #ffffff;
}

.handle-preloader .animation-preloader .spinner {
    border: 3px solid #fff;
    border-top-color: rgba(255, 255, 255, 0.3);
}

/* AnimaciÃ³n del preloader */
@keyframes spinner {
    to {
        transform: rotateZ(360deg);
    }
}

@keyframes letters-loading {

    0%,
    75%,
    100% {
        opacity: 0;
        transform: rotateY(-90deg);
    }

    25%,
    50% {
        opacity: 1;
        transform: rotateY(0deg);
    }
}

@media screen and (max-width: 767px) {
    .handle-preloader .animation-preloader .spinner {
        height: 8em;
        width: 8em;
    }
}

@media screen and (max-width: 500px) {
    .handle-preloader .animation-preloader .spinner {
        height: 7em;
        width: 7em;
    }

    .handle-preloader .animation-preloader .txt-loading .letters-loading {
        font-size: 30px;
        letter-spacing: 5px;
    }
}


.centred {
    text-align: center;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}


figure {
    margin: 0px;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-property: all;
}

.row {
    --bs-gutter-x: 30px;
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

html.lenis {
    height: auto;
}

/** button **/
.termtext {
    text-align: justify;
}

.theme-btn {
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--title-font);
    color: var(--title-color) !important;
    text-align: center;
    border-radius: 50px;
    padding: 15px 40px;
    z-index: 1;
    transition: all 0.5s ease-in-out 0.0s;
}

.theme-btn:hover {
    background: var(--secondary-color);
    color: #fff !important;
}

.theme-btn.btn-one {}

.theme-btn.btn-two {
    background: #111;
}

.theme-btn:before {
    position: absolute;
    content: '';
    width: 0%;
    height: 100%;
    left: 0px;
    top: 0px;
    transform: translateX(0);
    transform-origin: left center;
    z-index: -1;
    background: #0978f2;
    transition: all 500ms ease;
}

.theme-btn:after {
    position: absolute;
    content: '';
    width: 0%;
    height: 100%;
    right: 0px;
    top: 0px;
    transform: translateX(0);
    transform-origin: right center;
    z-index: -1;
    background: #044787;
    transition: all 500ms ease;
}

.theme-btn:hover:before,
.theme-btn:hover:after {
    transform: translateX(1);
    width: 50%;
}

.theme-btn span {
    position: relative;
    padding-right: 22px;
}

.theme-btn span:before {
    position: absolute;
    content: '\e901';
    font-family: 'icomoon';
    font-size: 14px;
    top: -3px;
    right: 0px;
    font-weight: 400;
}


.parallax-bg {
    position: absolute;
    left: 0px;
    top: -30%;
    width: 100%;
    height: calc(100% + 30%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


/** styled-pagination */

.pagination {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination li {
    position: relative;
    display: inline-block;
    margin: 0px 3px;
}

.pagination li a {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-family: var(--title-font);
    color: var(--text-color);
    height: 50px;
    width: 50px;
    line-height: 50px;
    border: 1px solid #E5E5E5;
    font-weight: 500;
    text-align: center;
    border-radius: 50%;
    z-index: 1;
    transition: all 500ms ease;
}

.pagination li a:hover,
.pagination li a.current {
    color: var(--title-color);
}

.pagination li a i {
    font-size: 12px;
}

.sec-pad {
    padding: 142px 0px 150px 0px;
}

.mr-0 {
    margin: 0px !important;
}

/** scroll-to-top **/

.scroll-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    box-shadow: inset 0 0 0 2px #2671A9;
    border-radius: 50%;
    z-index: 90000;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(15px);
    -ms-transform: translateY(15px);
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.scroll-to-top.scroll-top-active {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.scroll-to-top::after {
    position: absolute;
    content: "\e914";
    font-family: 'icomoon';
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    color: #2671A9;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    z-index: 2;
    transform: rotate(-180deg);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear
}

.scroll-to-top:hover::after {}

.scroll-to-top:hover::before {
    opacity: 1
}

.scroll-to-top .scroll-top-inner path {
    stroke-width: 4;
    fill: transparent;
    box-sizing: border-box;
    stroke: var(--theme-color);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}



@-webkit-keyframes ripple {
    70% {
        -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes ripple {
    70% {
        -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}



.sec-title {
    position: relative;
    display: block;
}

.sec-title h2 {
    position: relative;
    display: block;
    font-size: 48px;
    line-height: 53px;
}

.sec-title h2 span {}

.sec-title.light h2 {
    color: #fff;
}

.sec-title .sub-title {
    position: relative;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    font-family: var(--title-font);
    font-weight: 600;
    padding: 5px 17px;
    border-radius: 40px;
    text-transform: uppercase;
    z-index: 1;
}

.sec-title .sub-title:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    border-radius: 40px;
    z-index: -1;
    opacity: 0.1;
}

.sec-title .sub-title:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    border-radius: 40px;
    z-index: -1;
    opacity: 0.3;
    border: solid;
    border-width: 1px;
}

.bannercard {
    background: linear-gradient(to right, #60BBEE, #0A72AD);
    /* Gradient left to right */
    border: none;
    color: white;
    padding: 13px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.gradient-button {
    background: linear-gradient(to right, #60BBEE, #0A72AD);
    /* Gradient left to right */
    border: none;
    color: white;
    padding: 13px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.gradient-button:hover {
    background: linear-gradient(to right, #0A72AD, #60BBEE);
    /* Reverse gradient on hover */
}

.button-left {
    display: block;
    text-align: left;
    margin-left: 0;
}


.bannerclient {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
}

.right-content {
    text-align: right;
    /* Align text to the right */
    flex: 1;
    /* Adjust the width to take equal space */
}

/*** 
  
  ====================================================================
                          Home-Page-One
  ====================================================================
  
  ***/


/** main-header **/
.samplePdf {
    color: #ffffff !important;
    background: #06BDFF;
    border-radius: 18px;
    padding: 0.35rem 0.9rem;
    text-decoration: none;
    font-weight: 600 !important;
    font-family: 'Montserrat';
}


.main-header {
    position: relative;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 999;
    width: 100%;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.sticky-header {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.fixed-header .sticky-header {
    z-index: 999;
    opacity: 1;
    visibility: visible;
    -ms-animation-name: fadeInDown;
    -moz-animation-name: fadeInDown;
    -op-animation-name: fadeInDown;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -ms-animation-duration: 500ms;
    -moz-animation-duration: 500ms;
    -op-animation-duration: 500ms;
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
    -ms-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -op-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -ms-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -op-animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.bannertext {
    color: #141E27;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1.8rem;
    text-align: left;
}

.bannerrtext {
    color: #141E27;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 2rem;
    text-align: left;
}

.bannerpara {
    color: #ffffff;
    font-size: 13px;
    text-align: justify;
}

/** language-switcher **/

.language-picker label {
    display: none;
}

.language-picker .nice-select {
    display: none;
}

.explore-btn {
    background-color: #FA4A4A;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    padding: 5px 52px !important;
    border: none !important;
    border-radius: 30px !important;
    transition: background-color 0.3s ease;
}

.explore-btn:hover {
    background-color: #b23131;
}

.right-image {
    margin-left: 8px;
    max-width: 24px;
    height: auto;
}

.bannercards {
    position: relative;
    bottom: 175px;
}

.language-picker {
    display: flex;
    align-items: center;
    color: '#ffffff';
}

.language-picker__button .icon {
    display: none;
}

.language-picker__dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    width: 110px;
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 15px 15px;
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    text-align: left;
    transition: visibility 0s .2s, opacity .2s, -webkit-transform .2s ease-out;
    transition: visibility 0s .2s, opacity .2s, transform .2s ease-out;
    transition: visibility 0s .2s, opacity .2s, transform .2s ease-out, -webkit-transform .2s ease-out
}

.language-picker__button[aria-expanded=true]+.language-picker__dropdown {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(4px);
    -ms-transform: translateY(4px);
    transform: translateY(4px);
    transition: opacity .2s, -webkit-transform .2s ease-out;
    transition: opacity .2s, transform .2s ease-out;
    transition: opacity .2s, transform .2s ease-out, -webkit-transform .2s ease-out
}

.language-picker__item span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.language-picker__item:hover {}

.language-picker__button {
    position: relative;
    border: none !important;
    padding: 0px;
    padding-right: 7px;
}

.language-picker__button:before {
    position: absolute;
    content: '\e914';
    font-family: 'icomoon';
    font-size: 6px;
    top: 8px;
    right: 0px;
    color: var(--title-color);
}

.language-picker__flag {
    position: relative;
    font-size: 14px;
    line-height: 16px;
    font-family: var(--title-font);
    font-weight: 400;
    color: var(--title-color);
    padding-left: 33px;
    text-transform: uppercase;
}

.language-picker__list .language-picker__flag {
    padding: 4px 0px 4px 33px;
}

.language-picker__list .language-picker__flag.language-picker__label:after {
    position: absolute;
    content: '';
    /* background-image: url(../images/icons/icon-1.png); */
    width: 10px;
    height: 6px;
    top: 6px;
    right: 0px;
    background-repeat: no-repeat;
}

.language-picker__flag.language-picker__label {
    padding-left: 26px;
    padding-right: 0px;
    padding-right: 16px;
}

.language-picker__flag em {
    font-style: normal;
}

.language-picker__flag::before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    top: 4px;
    left: 0px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.language-picker__flag.language-picker__label:before {
    top: -3px;
    left: 0px;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: 40px;
    background-color: #007bff;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.corporate .icon-text {
    display: flex;
    align-items: center;
}

.corporate .icon-box {
    margin-right: 10px;
}

.corporate h4 {
    margin: 0;
}

.my-float {
    margin-top: 16px;
}

.floatt {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: 110px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-floatt {
    margin-top: 16px;
}

.language-picker__dropdown .language-picker__flag::before {
    margin-right: .5rem;
    margin-right: var(--space-xs);
    top: -1px;
}

/* .language-picker__flag--deutsch::before {
    background-image: url(../images/icons/flag-deutsch.png);
  }
  
  .language-picker__flag--english::before {
    background-image: url(../images/indianflag.png);
  }
  
  .language-picker__flag--francais::before {
    background-image: url(../images/icons/flag-francais.png);
  }
  
  .language-picker__flag--italiano::before {
    background-image: url(../images/icons/flag-italiano.png);
  } */

.language-picker--hide-label .language-picker__button .icon {
    margin-left: 0
}

.language-picker--hide-label .language-picker__button em {
    display: none
}

.language-picker--hide-label .language-picker__button .language-picker__flag::before {
    margin-right: .25rem;
    margin-right: var(--space-xxxs)
}

.language-picker__flag.language-picker__label {
    padding-right: 7px;
}

.nice-select {
    position: relative;
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 26px;
    color: var(--text-color);
    font-family: var(--text-font);
    padding-right: 14px;
}

.nice-select:before {
    position: absolute;
    content: '\e914';
    font-family: 'icomoon';
    font-size: 7px;
    top: 0px;
    right: 25px;
}

.nice-select .list {
    min-width: 100px;
}

.nice-select .list li {
    color: var(--text-color);
}

.nice-select .list {
    max-height: 160px;
    overflow-x: hidden;
    overflow-y: auto;
}


.owl-dots-none .owl-dots,
.owl-nav-none .owl-nav {
    display: none !important;
}

.owl-nav button {
    background: transparent;
}

.float-bob-y {
    animation-name: float-bob-y;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: float-bob-y;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: float-bob-y;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob-y;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob-y;
    -o-animation-duration: 2s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.float-bob-x {
    animation-name: float-bob-x;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: float-bob-x;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: float-bob-x;
    -moz-animation-duration: 15s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob-x;
    -ms-animation-duration: 15s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob-x;
    -o-animation-duration: 15s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}


/** rtl-switcher **/

.demo-rtl {
    position: fixed;
    top: 390px;
    left: 10px;
    z-index: 9999;
}

button.rtl {
    background: var(--theme-color);
    display: block;
    text-indent: inherit;
    font-size: 12px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    margin: 0px;
    color: #fff !important;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    transition: all 500ms ease;
}

.demo-ltr {
    position: fixed;
    top: 390px;
    left: auto;
    right: 10px;
    z-index: 9999;
}

button.ltr {
    background: var(--theme-color);
    display: block;
    text-indent: inherit;
    font-size: 12px;
    font-weight: 700;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 0px;
    color: #fff !important;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 1.0);
    transition: all 500ms ease;
}

.boxed_wrapper.ltr .demo-rtl {
    display: block;
}

.boxed_wrapper.ltr .demo-ltr {
    display: none;
}

.boxed_wrapper.rtl .demo-rtl {
    display: none;
}

.boxed_wrapper.rtl .demo-ltr {
    display: block;
}

.nav-style-one .owl-nav button {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 70px;
    height: 70px;
    line-height: 74px;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    z-index: 1;
    transition: all 500ms ease;
}

.nav-style-one .owl-nav .owl-prev {
    margin-right: 10px;
}

.nav-style-one .owl-nav button:hover {
    background: transparent !important;
}

.nav-style-one .owl-nav button:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    border-radius: 50%;
    transform-origin: top center;
    transform: scaleY(0);
    z-index: -1;
    transition: all 500ms ease;
}

.nav-style-one .owl-nav button:hover:before {
    transform: scaleY(1);
}

.progress-box .bar {
    position: relative;
    width: 100%;
    height: 8px;
    background: #E3E7EB;
    border-radius: 4px;
}

.progress-box .bar-inner {
    position: relative;
    display: block;
    width: 0px;
    height: 8px;
    border-radius: 4px;
    -webkit-transition: all 1500ms ease;
    -ms-transition: all 1500ms ease;
    -o-transition: all 1500ms ease;
    -moz-transition: all 1500ms ease;
    transition: all 1500ms ease;
}

.accordion-box .block .acc-content {
    position: relative;
    display: none;
}

.accordion-box .block .acc-content.current {
    display: block;
}

.nice-select .option {
    padding-left: 15px;
    padding-right: 15px;
}

.tabs-box .tab {
    position: relative;
    display: none;
    transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
}

.tabs-box .tab.active-tab {
    display: block;
}

.tabs-box .tab {
    transform: scale(0.9, 0.9) translateY(0px);
}

.tabs-box .tab.active-tab {
    transform: scale(1) translateY(0px);
}

.border-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    border: 1px dashed rgba(255, 255, 255, 0.50);
    animation: squares 2.9s linear 0s infinite;
    -webkit-animation: squares 2.9s linear 0s infinite;
    -ms-animation: squares 2.9s linear 0s infinite;
    -o-animation: squares 2.9s linear 0s infinite;
    -webkit-animation-play-state: running;
    -moz-animation-play-state: running;
    -o-animation-play-state: running;
    animation-play-state: running;
    opacity: 0;
}

.border-animation.border-2 {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
}

.border-animation.border-3 {
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
}


.search-popup {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    background: rgba(0, 0, 0, 0.80);
    -webkit-transform: translateY(101%);
    -ms-transform: translateY(101%);
    transform: translateY(101%);
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
}

.search-popup.popup-visible {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}

.search-popup .popup-inner {
    width: 100%;
    height: 100%;
    background: #fff;
}

.search-popup .upper-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 70px 70px;
}

.search-popup .overlay-layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
}

.search-popup .close-search {
    position: relative;
    font-size: 16px;
    color: var(--title-color);
    cursor: pointer;
    z-index: 5;
    transition: all 500ms ease;
}

.search-popup .close-search:hover {
    color: red;
}

.search-popup .search-form {
    position: relative;
    width: 100%;
    padding: 100px 0px 250px 0px;
}

.search-popup .search-form .form-group {
    position: relative;
    margin: 0px;
}

.search-popup .search-form fieldset input[type="search"] {
    position: relative;
    height: 90px;
    padding: 20px 0px;
    background: #ffffff;
    line-height: 30px;
    font-size: 24px;
    color: #808080;
    font-family: 'Inter', sans-serif;
    border: none;
    border-radius: 0px;
    padding-right: 50px;
    border-bottom: 1px solid #e5e5e5;
}

.search-popup .search-form fieldset button[type="submit"] {
    position: absolute;
    top: 30px;
    right: 0px;
    font-size: 22px;
    color: #141417;
    cursor: pointer;
    transition: all 500ms ease;
}

.search-popup .search-form fieldset input[type="search"]:focus {
    border-color: #141417;
}

.search-popup .form-control:focus {
    box-shadow: none !important;
}

.banner-btn {
    background: #fff;
}

.banner-btn:hover,
.banner-btn:before,
.banner-btn:after {}

.list-style-one li {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 14px;
    padding-left: 40px;
}

.list-style-one li:last-child {
    margin-bottom: 0px;
}

.list-style-one li:before {
    position: absolute;
    content: '\e902';
    font-family: 'icomoon';
    font-size: 12px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50%;
    left: 0px;
    top: 0px;
}

.list-style-one li:after {
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
    opacity: 0.2;
    left: 0px;
    top: 0px;
}


.slide-text {
    position: relative;
    display: block;
}

.slide-text .text-inner {
    position: relative;
    display: block;
    padding: 25px 0px;
    transform: rotate(-5deg);
}

.slide-text .text-inner:before {
    position: absolute;
    content: '';
    left: -50px;
    top: 0px;
    width: calc(100% + 100px);
    height: 100%;
}

.slide-text .text-inner .text-list {
    position: relative;
    display: flex;
    align-items: center;
    width: max-content;
    -moz-animation: scroll-left 400s linear infinite;
    -webkit-animation: scroll-left 400s linear infinite;
    animation: scroll-left 400s linear infinite;
}

.slide-text .text-inner .text-list li {
    position: relative;
    display: inline-block;
    font-size: 32px;
    line-height: 40px;
    font-family: var(--title-font);
    color: #ffffff;
    margin-right: 280px;
}

.slide-text .text-inner .text-list li:last-child {
    margin: 0px;
}

@-moz-keyframes scroll-left {
    0% {
        -moz-transform: translateX(0%);
    }

    100% {
        -moz-transform: translateX(-100%);
    }
}

@-webkit-keyframes scroll-left {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-100%);
    }
}

.slide-text .text-inner .text-list li:before {
    position: absolute;
    content: '';
    /* background-image: url(../images/icons/icon-1.png); */
    width: 37px;
    height: 37px;
    top: 2px;
    right: -145px;
    background-repeat: no-repeat;
}


.image-hov-one {
    position: relative;
    display: block;
    overflow: hidden;
}

.image-hov-one::before {
    background: rgba(255, 255, 255, 0.5);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    opacity: 1;
    z-index: 1;
    pointer-events: none;
}

.image-hov-one:hover::before {
    width: 100%;
    opacity: 0;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.image-hov-two {
    position: relative;
    display: block;
    overflow: hidden;
}

.image-hov-two::before {
    background: rgba(255, 255, 255, 0.5);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1000%;
    height: 0%;
    opacity: 1;
    z-index: 1;
    pointer-events: none;
}

.image-hov-two:hover::before {
    height: 100%;
    opacity: 0;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.dueal-section {
    background: #EFF2E6;
}

.dueal-section .bg-color {
    position: absolute;
    content: '';
    background: var(--secondary-color);
    width: 50%;
    height: 100%;
    top: 0px;
    right: 0px;
}

.dueal-section.alternat-2 .content_block_three .content-box {
    margin-left: -150px;
}

.dueal-section.alternat-2 .content_block_two .content-box .inner-box .single-item h3 a,
.dueal-section.alternat-2 .content_block_two .content-box .inner-box .single-item p {
    color: #fff;
}

.dueal-section.alternat-2 .content_block_two .content-box .inner-box .single-item h3 a:hover {}

.dueal-section .shape .shape-1 {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 277px;
    height: 334px;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 167px 167px 167px 0px;
}

.dueal-section .shape .shape-2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    width: 450px;
    height: 543px;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 255px 0px 255px 255px;
    margin-left: -225px;
}

.dueal-section .shape .shape-3 {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 277px;
    height: 334px;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 167px 167px 0px 167px;
}

.dueal-section .shape .shape-4 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    width: 450px;
    height: 543px;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 0px 255px 255px 255px;
    margin-left: 225px;
}

.banner-btn-two {
    border: 1px solid #fff;
    padding: 14px 40px;
    color: #fff !important;
}

.banner-btn-two:hover {
    border-color: var(--secondary-color);
}

.dueal-section.alternat-3 {
    background: transparent;
}

.dueal-section .outer-container {
    background: #EFF2E6;
    border-radius: 20px;
    overflow: hidden;
}

.dueal-section.alternat-3 .content_block_three .content-box {
    margin-left: -100px;
}

.dueal-section .testimonial-content {
    position: relative;
    display: block;
    margin-right: -70px;
}

.dots-style-one .owl-dots {
    position: relative;
    display: block;
}

.dots-style-one .owl-dots button span {
    display: none !important;
}

.dots-style-one .owl-dots button {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #D9D9D9;
    cursor: pointer;
    margin: 0px 6px;
    transition: all 500ms ease;
}

.dots-style-one .owl-dots button.active,
.dots-style-one .owl-dots button:hover {
    opacity: 1;
    transform: scale(1.8);
}

.dueal-section .dots-style-one .owl-dots {
    margin-top: 20px;
}

.slide-text.alternat-2 .text-inner {
    background: transparent;
}

.slide-text.alternat-2 .text-inner:before {
    background: transparent;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}

.d_block {
    display: block !important;
}

.header-style-three {
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.06);
}

.dueal-section.service-page {
    padding-bottom: 450px;
}

.dueal-section .hiring-content {
    position: relative;
    display: block;
}

.dueal-section .hiring-content h2 {
    position: relative;
    display: block;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 20px;
}

.dueal-section .hiring-content p {
    margin-bottom: 50px;
    color: var(--title-color);
}

.dueal-section .hiring-content.light h2,
.dueal-section .hiring-content.light p {
    color: #fff;
}

.dueal-section .hiring-content.light .theme-btn:hover {
    color: var(--title-color) !important;
}

.dueal-section .hiring-content.light .theme-btn:before,
.dueal-section .hiring-content.light .theme-btn:after {
    background: #fff;
}

.b_radius_0 {
    border-radius: 0px !important;
}


.check-box input {
    display: none;
}

.check-box label {
    position: relative;
    font-size: 14px;
    line-height: 30px;
    color: var(--title-color);
    padding-left: 24px;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 0px;
}

.check-box label:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 7px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    border-color: #DDDDDD;
    background: transparent;
}

.check-box label:after {
    position: absolute;
    content: '\e919';
    font-family: 'icomoon';
    left: 3px;
    top: 1px;
    opacity: 0;
    font-size: 7px;
    color: #fff;
}

.check-box input:checked+label:after {
    opacity: 1;
}

.check-box input:checked+label:before {}



.main-header {
    position: relative;
}

.main-header .outer-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-style-one {
    position: absolute;
    left: 0px;
    top: 50px;
    width: 100%;
}

.header-style-one .outer-box {
    background: #fff;
    border-radius: 60px;
    padding: 0px 15px;
}

.header-style-one .header-lower .outer-box:before {
    position: absolute;
    content: '';
    background: rgba(255, 255, 255, 0.15);
    width: 100%;
    height: 100%;
    border-radius: 60px;
    left: 0px;
    top: 8px;
}


.main-header .menu-right-content {
    position: relative;
    display: flex;
    align-items: center;
}

.main-header .menu-right-content .search-btn button {
    position: relative;
    display: inline-block;
    font-size: 20px;
    color: var(--title-color);
    transition: all 500ms ease;
}

.main-header .menu-right-content .search-btn button:hover {}

.main-header .menu-right-content .link-box a {
    position: relative;
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    font-family: var(--title-font);
    color: var(--title-color);
    font-weight: 500;
    text-transform: capitalize;
}

.main-header .menu-right-content .link-box a:hover {}

.main-header .menu-right-content .theme-btn {
    padding: 10px 25px;
}

.navbar {
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}


.scrolled-navbar {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out;
}

/** main-menu **/

.main-menu {
    float: left;
}

.main-menu .navbar-collapse {
    padding: 0px;
    display: block !important;
}

.main-menu .navigation {
    margin: 0px;
}

.main-menu .navigation>li {
    position: inherit;
    float: left;
    z-index: 2;
    margin: 0px 19px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.main-menu .navigation>li:last-child {
    margin-right: 0px !important;
}

.main-menu .navigation>li:first-child {
    margin-left: 0px !important;
}

.main-menu .navigation>li>a {
    position: relative;
    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    padding-top: 31px;
    padding-bottom: 31px;
    font-weight: 500;
    font-family: var(--title-font);
    opacity: 1;
    color: var(--title-color);
    z-index: 1;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.main-menu .navigation>li.dropdown>a {
    padding-right: 17px;
}

.main-menu .navigation>li.current>a,
.main-menu .navigation>li:hover>a {}

.main-menu .navigation>li.dropdown>a:before {
    position: absolute;
    content: "\f107";
    font-family: 'Font Awesome 5 Pro';
    top: 31px;
    right: 0px;
    font-weight: 500;
    transition: all 500ms ease;
}

.header-style-one .main-menu .navigation>li>a:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    right: 0px;
    bottom: 0px;
    transform: scale(0, 0);
    transition: all 500ms ease;
}

.header-style-one .main-menu .navigation>li.current>a:after,
.header-style-one .main-menu .navigation>li:hover>a:after {
    transform: scale(1, 1);
}

.main-menu .navigation>li>ul,
.main-menu .navigation>li>.megamenu {
    position: absolute;
    left: inherit;
    top: 100%;
    width: 230px;
    margin-top: 15px;
    padding: 15px 30px;
    z-index: 100;
    display: none;
    background: #fff;
    opacity: 0;
    border-bottom: solid;
    border-width: 3px;
    visibility: hidden;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
    border-radius: 0px;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul.from-right {
    left: auto;
    right: 0px;
}

.main-menu .navigation>li>ul>li {
    position: relative;
    width: 100%;
}

.main-menu .navigation>li>ul>li>a,
.main-menu .navigation>li>.megamenu li>a {
    position: relative;
    display: block;
    padding: 5px 0px;
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    font-family: var(--title-font);
    color: var(--title-color);
    text-align: left;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-menu .navigation>li>.megamenu li>a {
    padding-left: 0px;
}

.main-menu .navigation>li>.megamenu h4 {
    display: block;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
}

.main-menu .navigation>li>ul>li>a:hover,
.main-menu .navigation>li>.megamenu li>a:hover {
    letter-spacing: 1px;
}

.main-menu .navigation>li>ul>li:last-child>a,
.main-menu .navigation>li>.megamenu li:last-child>a {
    border-bottom: none;
}

.main-menu .navigation>li>ul>li.dropdown>a:after {
    font-family: 'Font Awesome 5 Pro';
    content: "\f105";
    position: absolute;
    right: 0px;
    top: 6px;
    display: block;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    z-index: 5;
}

.main-menu .navigation>li>ul>li>ul {
    position: absolute;
    left: 100%;
    top: 0%;
    margin-left: 30px;
    width: 230px;
    margin-top: 15px;
    padding: 15px 30px;
    z-index: 100;
    display: none;
    background: #fff;
    opacity: 0;
    border-bottom: solid;
    border-width: 3px;
    visibility: hidden;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
    border-radius: 0px;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul:before {
    position: absolute;
    content: '';
    background: transparent;
    width: 30px;
    height: 100%;
    left: -30px;
    top: 0px;
}

.main-menu .navigation>li>ul>li>ul.from-right {
    left: auto;
    right: 0px;
}

.main-menu .navigation>li>ul>li>ul>li {
    position: relative;
    width: 100%;
}

.main-menu .navigation>li>ul>li>ul>li:last-child {
    border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li>a {
    position: relative;
    display: block;
    padding: 5px 0px;
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    font-family: var(--title-font);
    color: var(--title-color);
    text-align: left;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul>li:last-child>a {
    border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li>a:hover {
    letter-spacing: 1px;
}

.main-menu .navigation>li.dropdown:hover>ul,
.main-menu .navigation>li.dropdown:hover>.megamenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0px;
    top: 100%;
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
    visibility: visible;
    opacity: 1;
    top: 0%;
    margin-top: 0px;
}

.main-menu .navigation li.dropdown .dropdown-btn {
    position: absolute;
    right: -32px;
    top: 66px;
    width: 34px;
    height: 30px;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    color: #3b3b3b;
    cursor: pointer;
    display: none;
    z-index: 5;
    transition: all 500ms ease;
}

.main-menu .navigation li.current.dropdown .dropdown-btn,
.main-menu .navigation li:hover .dropdown-btn {}

.main-menu .navigation li.dropdown ul li.dropdown .dropdown-btn {
    display: none;
}

.menu-area .mobile-nav-toggler {
    position: relative;
    float: right;
    font-size: 40px;
    line-height: 50px;
    cursor: pointer;
    background: #0a3f6a;
    display: none;
}

.mobile-menu .nav-logo img {
    max-width: 160px;
}

.menu-area .mobile-nav-toggler .icon-bar {
    position: relative;
    height: 2px;
    width: 30px;
    display: block;
    margin-bottom: 5px;
    background-color: #fff;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.menu-area .mobile-nav-toggler .icon-bar:last-child {
    margin-bottom: 0px;
}


/** megamenu-style **/

.main-menu .navigation>li.dropdown>.megamenu {
    position: absolute;
    width: 100%;
    padding: 30px 50px;
    left: 0px;
}

.main-menu .navigation li.dropdown .megamenu li h4 {
    margin-bottom: 10px;
}


/** mobile-menu **/

.nav-outer .mobile-nav-toggler {
    position: relative;
    float: right;
    font-size: 40px;
    line-height: 50px;
    cursor: pointer;
    color: #3786ff;
    display: none;
}

.mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 999999;
    transition: all 900ms ease;
}

.mobile-menu .navbar-collapse {
    display: block !important;
}

.mobile-menu .nav-logo {
    position: relative;
    padding: 50px 25px;
    text-align: left;
    padding-bottom: 100px;
}

.mobile-menu-visible {
    overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
    opacity: 1;
    visibility: visible;
}

.mobile-menu .menu-backdrop {
    position: fixed;
    left: 0%;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 900ms ease;
    background-color: #000;
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
    opacity: 0.70;
    visibility: visible;
    right: 100%;
    -webkit-transition: all .8s ease-out 0s;
    -o-transition: all .8s ease-out 0s
}

.mobile-menu .menu-box {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    background: #033b5c;
    padding: 0px 0px;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    border-radius: 0px;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    transition: all 900ms ease !important;
}

.mobile-menu-visible .mobile-menu .menu-box {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.7s ease;
    -o-transition: all 0.7s ease;
    transition: all 0.7s ease;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

.mobile-menu .close-btn {
    position: absolute;
    right: 25px;
    top: 10px;
    line-height: 30px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
    z-index: 10;
}

.mobile-menu .navigation {
    position: relative;
    display: block;
    width: 100%;
    float: none;
}

.mobile-menu .navigation li {
    position: relative;
    display: block;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation li>ul>li:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation li>a {
    position: relative;
    display: block;
    line-height: 24px;
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.mobile-menu .navigation li ul li>a {
    font-size: 16px;
    margin-left: 20px;
    text-transform: capitalize;
}

.mobile-menu .navigation li>a:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    border-left: 5px solid #fff;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.mobile-menu .navigation li.current>a:before {
    height: 100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 32px;
    height: 32px;
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.10);
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open {
    color: #ffffff;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul,
.mobile-menu .navigation>li.dropdown>.megamenu {
    display: none;
}

.mobile-menu .social-links {
    position: relative;
    padding: 0px 25px;
}

.mobile-menu .social-links li {
    position: relative;
    display: inline-block;
    margin: 0px 10px 10px;
}

.mobile-menu .social-links li a {
    position: relative;
    line-height: 32px;
    font-size: 16px;
    color: #ffffff;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.mobile-menu .social-links li a:hover {}

div#mCSB_1_container {
    top: 0px !important;
}

.mobile-menu .contact-info {
    position: relative;
    padding: 120px 30px 20px 30px;
}

.mobile-menu .contact-info h4 {
    position: relative;
    font-size: 20px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 20px;
}

.mobile-menu .contact-info ul li {
    position: relative;
    display: block;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.80);
    margin-bottom: 3px;
}

.mobile-menu .contact-info ul li a {
    color: rgba(255, 255, 255, 0.80);
}

.mobile-menu .contact-info ul li a:hover {}

.mobile-menu .contact-info ul li:last-child {
    margin-bottom: 0px;
}

.header-icon {
    width: 24px;
    height: 24px;
}

/** header-style-two **/

.header-style-two {
    position: relative;
    background: #EFF2E6;
}

.header-top .info li a:hover {}

.header-top .info li img {
    position: absolute;
    left: 0px;
    top: 0px;
}

.header-top p {
    font-size: 14px;
    color: var(--title-color);
}

.header-top p span {
    font-weight: 600;
    text-transform: uppercase;
    color: #FF3E3E;
}

.header-top .right-column {
    position: relative;
    display: flex;
    align-items: center;
}

.header-top .social-links {
    position: relative;
    display: flex;
    align-items: center;
}

.header-top .social-links li {
    position: relative;
    display: flex;
    margin-right: 10px;
}

.header-top .social-links li:last-child {
    margin: 0px !important;
}

.header-top .social-links li span {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--title-color);
}

.header-top .social-links li a {
    position: relative;
    display: inline-block;
    font-size: 20px;
    line-height: 20px;
    color: #2671ae;
}

.header-top .social-links li a:hover {}


/** header-style-three **/

.header-style-three {
    position: relative;
    display: block;
}

.header-style-three .header-top {
    background: #0a3f6a;
    border-bottom: none;
}

.header-style-three .header-top .info li,
.header-style-three .header-top .info li a {
    color: #fff;
}

.header-style-three .header-top .info li a:hover {
    text-decoration: underline;
}

.header-style-three .language-picker__flag,
.header-style-three .language-picker__button:before {
    color: #fff;
}

.header-style-three .language-picker__list li a {
    color: var(--text-color);
}

.header-style-three .header-top .social-links li span,
.header-style-three .header-top .social-links li a {
    color: #fff;
}

.ourstory {}

.ourstory-container {
    background-color: #FFF;
    padding-top: 1.875rem;
    padding-bottom: 1rem;
}

.ourstory-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-items: center;
    gap: 15px;
    padding-top: 10px;
    padding-bottom: 19px;
}

.ourstorypara {
    font-size: 1rem;
    color: #333;
    font-family: 'Montserrat';
    text-align: justify;
    line-height: 24px;
    padding: 0 2rem;
    text-indent: 1.5em;
}

.whyuspara {
    font-size: 1rem;
    color: #333333;
    font-family: 'Montserrat';
    text-align: justify;
    line-height: 24px;
    text-indent: 1.5em;
    padding-top: 1rem;
    padding-right: 1rem;
}

.ourstory-image {
    width: auto;
}

.ourstory-head {
    font-size: 1.875rem;
    color: #000000;
    margin: 0;
    font-family: 'Montserrat';
    font-weight: 600;
}

.image-row-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.infra-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.quality {}

.quality-head {
    font-size: 1.875rem;
    color: #000000;
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: right;
    padding-right: 1rem;
}

.infraimage-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin-top: 1.5rem;
}

.infraimage-row-item {
    width: 32%;
    max-height: 28.125rem;
}

.image-row {
    display: flex;
    flex-direction: row;
}

.image-row-item {
    max-height: 20.25rem;
    margin: 0;
    padding: 0;
}

.whyus-head {
    font-size: 1.875rem;
    color: #000000;
    margin: 0;
    text-align: left;
    font-family: 'Montserrat';
    font-weight: 600;
}

.aboutpara {
    margin-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1rem;
    color: #333;
    font-family: 'Montserrat';
    text-align: justify;
    line-height: 24px;
}

.aboutHome {
    margin-top: 1rem;
    padding-right: 2rem;
    font-size: 1rem;
    color: #333;
    font-family: 'Montserrat';
    text-align: justify;
    line-height: 24px;
}

.about {
    position: relative;
    /* bottom: 159px; */
}

.aboutus {
    position: relative;
    bottom: 159px;
}

.brands {
    margin-top: -140px;
}

/* .brands img {
    height: 10rem;
    width: auto;
} */

.projectparatext {
    color: #ffffff;
}

.projectheadtext {
    color: #ffffff;
    font-family: 'Montserrat';
    font-weight: 600;
}

/** header-style-four **/
.top-header {
    background-color: #f8f9fa;
    padding: 10px 20px;
    position: relative;
    z-index: 1000;
}

.contact-info h6 {
    margin-bottom: 0;
    font-size: 14px;
}

.product-card {
    padding: 1.5em 0.5em 0.5em;
    text-align: center;
    border-radius: 2em;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
}

.product-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.product-card img {
    margin: 0 auto;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.product-card .card-title {
    font-weight: bold;
    /* Bold title */
    font-size: 1.5em;
    /* Title font size */
}

.product-card .btn-primary {
    border-radius: 2em;
    /* Rounded button */
    padding: 0.5em 1.5em;
    /* Button padding */
}

.product-image {
    border-radius: 19px;
}

.markettext {
    border: 1px solid #605555;
    padding: 5px;
    border-radius: 23px;
}





.card-bodyy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.2rem;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 7px;
    width: 179px;
    border-radius: 5px;
}

.card:hover img {
    opacity: 0.8;
}

.card:hover .card-bodyy {
    background-color: rgba(0, 0, 0, 0.8);
}


.card-body {
    text-align: center;
}


.gradient-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: #ffffff;
    z-index: 1;
}


.card-title {
    position: relative;
    z-index: 2;
    color: #ffffff;
    font-size: 19px;
    padding: 8px 0
}

.contact-info {
    display: flex;
    align-items: center;
}

.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    cursor: pointer;
}

.hover-card {
    position: relative;
    overflow: hidden;
    border: none;
}

.card-image {
    display: block;
    width: 100%;
    height: auto;
}


.card-titlee {
    background-color: #ffffff;
    color: #000000;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    padding: 23px;
    text-align: center;
    border-top: 1px solid #ccc;
}

.overlay {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 59%);
    z-index: 1;
    border-radius: 12px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.hover-card:hover .overlay {
    opacity: 1;
}

.overlay-text {
    position: absolute;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 2;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.hover-card:hover .overlay-text {
    opacity: 1;
}

.explore-more {
    display: inline-block;
    padding: 10px 20px;
    font-size: 13px;
    font-weight: bold;
    color: white;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 30px;
    text-transform: uppercase;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.explore-more:hover {
    color: #ffffff;
    transform: scale(1.1);
}

.email-icon {
    position: fixed;
    bottom: 20px;
    left: 89px;
    z-index: 1000;
    cursor: pointer;
}

.body-name {
    background-color: #055CA8;
    position: relative;
    bottom: 1.2rem;
    border-radius: 7px;
    margin: 0 13%;
    padding: 0.85rem;
}

.aboutbtn {
    background-color: #D2EAFF;
    border-radius: 15px;
    padding: 9px 16px 11px 22px;
    top: 23px;
    color: #044176;
    font-weight: 600;
    position: relative;
}

.main-footer .footer-column ul {
    text-align: justify;
}

.team-section .carousel-control-prev,
.team-section .carousel-control-next {
    width: 5%;
    z-index: 1;
    background: none;
}

/* .team-section .carousel-control-prev-icon,
.team-section .carousel-control-next-icon {
    filter: brightness(0) invert(1); 
} */

.team-img {
    margin: auto;
    max-width: 100%;
    height: 179px;
    display: block;
}

.team-card {
    margin-bottom: 20px;
}

.membertitletext {
    font-weight: 500;
    font-size: 0.9rem !important;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.designationtext {
    font-size: 0.7rem !important;
    font-weight: 500 !important;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.carousel-control-prev-icon {
    background-color: #777777;
    right: 23px;
    position: relative;
    border-radius: 7px;
}

.carousel-control-next-icon {
    background-color: #777777;
    left: 27px;
    position: relative;
    border-radius: 7px;
}

.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
    background-color: #3e3a3a;
}


.email-icon img {
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.homeActive {
    background-color: #fff;
    border-radius: 20px;
    color: #292D32 !important;
}

.navLinkActive {
    background-color: #0179D4;
    border-radius: 20px;
    color: #ffffff !important;
}

.carousel-item {
    text-align: center;
}

.carousel-item img {
    margin: 0 auto;
    border-radius: 10px;
}

.whatsapp-icon img {
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.contact-info h6 {
    margin-right: 20px;
}

.logo {
    height: 49px;
    /* Set desired height */
    width: auto;
    /* Maintain aspect ratio */
}

.bordered-row {
    border: 1px solid #D6D6D6;
    position: relative;
    border-radius: 5px;
    height: 105px;
}

.markertpara {
    font-family: 'Montserrat';
    font-size: 13px;
    color: #292D32;
    font-weight: 500;
    text-align: left;
    width: 153px;
}

/* .home-section {
    background-color: #f0f8ff;
    padding: 50px 0;
    margin-top: 93px;
} */

.home-text {
    font-size: 24px;
    color: #333;
}

.home-image {
    text-align: center;
}

.home-image img {
    max-width: 100%;
    height: auto;
}

.social-icons {
    display: flex;
    align-items: center;
}

.social-icons .nav-link img {
    margin-left: 10px;
    vertical-align: middle;
}

.header-style-four .main-menu .navigation>li>a:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    right: 0px;
    top: 0px;
    transform: scale(0, 0);
    transition: all 500ms ease;
}

.header-style-four .main-menu .navigation>li.current>a:after,
.header-style-four .main-menu .navigation>li:hover>a:after {
    transform: scale(1, 1);
}


.header-style-five .header-top {
    border: none;
    background: var(--secondary-color);
}

.header-style-five .header-top .info li a,
.header-style-five .header-top .info li,
.header-style-five .header-top p {
    color: #fff;
}

.header-style-five .language-picker__flag,
.header-style-five .language-picker__button:before,
.header-style-five .header-top .social-links li span {
    color: #fff;
}

.header-style-five .header-top .social-links li a {
    color: rgba(255, 255, 255, 0.80);
}

.header-style-five .header-top .social-links li a:hover {}

.header-style-five .language-picker__list li a {
    color: var(--text-color);
}



/** rtl-css **/

.rtl .main-header .menu-right-content .search-btn,
.rtl .main-header .menu-right-content .link-box {
    margin-right: 0px;
    margin-left: 20px;
}

.rtl .header-style-one .outer-box .logo-box {
    padding-left: 0px;
    padding-right: 15px;
}

.rtl .header-top .info li {
    margin-right: 0px;
    margin-left: 30px;
}

.rtl .language-picker {
    margin-right: 0px;
    margin-left: 30px;
}

.rtl .header-top .social-links li {
    margin-right: 0px;
    margin-left: 10px;
}


/** responsive-css **/


@media only screen and (max-width: 1349px) {}

@media only screen and (max-width: 1200px) {
    .header-top .top-inner {
        display: flex;
        text-align: center;
    }

    .header-top .info,
    .header-top .right-column {
        justify-content: center;
    }

    .main-header .outer-box {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 599px) {
    .main-header .menu-right-content {
        display: none;
    }

    .header-style-one .outer-box .logo-box {
        padding-left: 0px;
    }

    .rtl .header-style-one .outer-box .logo-box {
        padding-right: 0px;
    }
}

@media only screen and (max-width: 499px) {

    .main-header .outer-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .header-top .info {
        display: block;
    }

    .main-header .logo-box {
        padding: 0px !important;
    }

}

.benefits {
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: 1.8;
    margin-bottom: 0.8rem;
    font-family: 'Montserrat';
    font-size: 0.8rem;
    color: #333;
    font-weight: 500;
}

.benefits img {
    width: 20px;
    height: 20px;
    border-radius: 4px;
}

.footerlogotext {
    color: #000000;
    font-weight: 500;
}

.main-footer {
    position: relative;
    background: #E3E3E3;
}

.main-footer .widget-section {
    border-bottom: 1px solid #CFCFCF;
}

.main-footer .logo-widget p {
    margin-bottom: 9px;
}

.main-footer .logo-widget .download-btn {
    position: relative;
    display: flex;
    align-items: center;
}

.main-footer .logo-widget .download-btn a {
    position: relative;
    display: inline-block;
    width: 182px;
    background: #000;
    padding: 11px 20px 11px 55px;
    font-size: 18px;
    line-height: 20px;
    font-family: var(--title-font);
    font-weight: 600;
    color: #fff;
    margin-right: 15px;
    border-radius: 10px;
}

.main-footer .logo-widget .download-btn a:hover {
    background: var(--secondary-color);
}

.main-footer .logo-widget .download-btn a img {
    position: absolute;
    left: 16px;
    top: 13px;
}

.main-footer .logo-widget .download-btn a:last-child {
    margin: 0px !important;
}

.main-footer .logo-widget .download-btn a span {
    position: relative;
    display: block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

.main-footer .widget-title {
    position: relative;
    display: block;
    margin-bottom: 20px;
    color: #ffffff;
}

.main-footer .widget-title h4 {
    display: block;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    text-align: justify;
}

.main-footer .links-widget .links-list li {
    position: relative;
    display: block;
}

.main-footer .links-widget .links-list li a {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
}

.center-text {
    text-align: center;
}

.main-footer .links-widget .links-list li a:hover {}


.footer-bottom {
    position: relative;
    padding: 7px 0px;
    background-color: #D3D3D3;
}

.footer-bottom .bottom-inner {
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.footer-bottom .bottom-inner .copyright p {
    line-height: 28px;
    color: var(--title-color);
}

.footer-bottom .bottom-inner .copyright p a {
    display: inline-block;
}

.footer-bottom .bottom-inner .copyright p a:hover {
    text-decoration: underline;
}

.main-footer .social-links {
    position: relative;
    display: flex;
    align-items: center;
}

.main-footer .social-links li {
    margin-right: 10px;
}

.main-footer .social-links li:last-child {
    margin: 0px !important;
}

.main-footer .social-links li a {
    position: relative;
    /* display: inline-block; */
    width: 44px;
    height: 44px;
    line-height: 48px;
    /* background: #fff; */
    text-align: center;
    border-radius: 50%;
    font-size: 20px;
    color: var(--title-color);
}

.main-footer .social-links li a:hover {
    background: var(--secondary-color);
}

.main-footer .social-links li h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}

.main-footer.home-2 {
    background: #EFF2E6;
}

.main-footer.light-footer {
    background: #1A1A1A;
}

.main-footer.light-footer .logo-widget p {
    color: #fff;
}

.main-footer.light-footer .social-links li a {
    background: rgba(255, 255, 255, 0.10);
    color: #fff;
}

.main-footer.light-footer .social-links li a:hover {
    background: #22522A;
}

.main-footer.light-footer .widget-title h4 {
    color: #fff;
}

.main-footer.light-footer .links-widget .links-list li a {
    color: rgba(255, 255, 255, 0.80);
}

.main-footer.light-footer .links-widget .links-list li a:hover {}

.main-footer.light-footer .widget-section {
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.main-footer.light-footer .footer-bottom .bottom-inner .copyright p {
    color: #fff;
}

.main-footer.light-footer .logo-widget .download-btn a {
    background: #fff;
    color: var(--title-color);
}

.main-footer.light-footer .social-links li h5 {
    color: #fff;
}


/** rtl-css **/

.rtl .main-footer .logo-widget .download-btn a {
    margin-right: 0px;
    margin-left: 15px;
}

.rtl .main-footer .logo-widget {
    margin-right: 0px;
    margin-left: 30px;
}

.rtl .main-footer .social-links li {
    margin-right: 0px;
    margin-left: 10px;
}

.spacing-div {
    margin-bottom: 20px;
}

.service-card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    background-color: #fff;
}

.service-card a:active {
    color: inherit;
    text-decoration: none;
}

.service-card img {
    margin-bottom: 10px;
}

.service-card:hover {
    transform: translateY(-10px);
    text-decoration: none;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}


@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 991px) {
    .footer-widget {
        margin: 0px 0px 30px 0px !important;
    }

    .main-footer .widget-section {
        padding-bottom: 70px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-bottom .bottom-inner {
        display: block;
        text-align: center;
    }

    .main-footer .social-links {
        justify-content: center;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 599px) {}

@media only screen and (max-width: 499px) {
    .main-footer .logo-widget .download-btn {
        display: block;
    }

    .main-footer .logo-widget .download-btn a {
        margin-right: 0px;
        margin-bottom: 15px;
    }

    .rtl .main-footer .logo-widget .download-btn a {
        margin-left: 0px;
    }
}

.breadcrumb-section {
    position: relative;
    text-align: center;
    padding: 50px 0;
}

.breadcrumb {

    padding: 10px 29px;
    border-radius: 5px;
}

.modal-content {
    width: 123px;
}

.sticky-social {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 10000;
}

.social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 43px;
    height: 43px;
    background-color: transparent;
    color: #ffffff;
    text-decoration: none;
    border-radius: 50%;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    /* Added box-shadow transition */
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    /* Initial shadow */
}

.social-link .icon {
    font-size: 24px;
    transition: transform 0.3s ease;
}

.social-link:hover {
    transform: scale(1.2);
    /* Scale up the link on hover */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    /* Darker shadow on hover */
}

.social-link:hover .icon {
    transform: scale(1.1);
    /* Scale the icon slightly on hover */
}


.link-text {
    display: none;
    margin-left: 10px;
}

.social-link:hover .link-text {
    display: inline;
}

.subtitle-text {
    color: #0a3f6a;
    font-size: 14px;
    margin-top: 5px;
}

/* Sidebar styling */
.service-sidebar .category-list li a {
    display: block;
    padding: 10px;
    color: #333;
    font-weight: bold;
    transition: background 0.3s;
}

.service-sidebar .category-list li a:hover,
.service-sidebar .category-list li .current {
    background: #2671ae;
    color: #fff;
    border-radius: 10px;
}

/* Service Block Styling */
.service-block-one .inner-box {
    padding: 0px 0px 0px 3px;
    margin-bottom: 30px;
    position: relative;
}

.service-block-one .inner-box:before {
    content: '';
    width: 100%;
    height: calc(100% - 90px);
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 10px;
    box-shadow: 1px 0 109px rgb(0 0 0 / 20%);
    transition: all 0.5s ease;
}

.service-block-one .inner-box:hover:before {
    box-shadow: 0 20px 80px rgba(0, 0, 0, 0.1);
}

.service-block-one .image-box img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 9px;
    position: relative;
}

.service-block-one .lower-content {
    background: #ffffff;
    padding: 10px;
    color: #3c3939;
    text-align: center;
}

.service-block-one .lower-content h3 {
    font-size: 16px;
    margin: 0;
}

.service-block-one .lower-content p {
    font-size: 13px;
}

.image-box {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    /* Optional, for rounded corners */
}

.image-box img {
    width: 100%;
    height: 259px;
    /* object-fit: cover; */
}



.image-box:hover .overlay-image {
    opacity: 1;
}

.lower-content {
    color: #fff;
    /* Ensures the text is visible on the overlay */
    text-align: center;
}

.contact-card {
    width: 23.8rem;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 11.3px 0px rgba(155, 155, 155, 0.25);
    padding: 1.1rem 1.5rem;
    height: 13.5rem;
}

.contact-card .card-title {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1.38rem;
    color: #1C56B2;
}

.contact-card .card-text {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.805rem;
    color: #666;
    text-align: justify;
    line-height: 24px;
}

.contact-card .map-box {
    display: inline-block;
    border: 1px solid #0179D4;
    padding: 0.3rem 1rem;
    cursor: pointer;
    font-size: 0.9rem;
    color: #0179D4;
    border-radius: 20px;
    text-align: center;
    width: 7.5rem;
}

.contact-card-iframe {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 0.9em;
}

.cardprivacy {
    background-color: #ffffff;
    box-shadow: 0px 0px 20.3px 0px #00000033;
    bottom: 49px;
    position: relative;
    z-index: 10;
}

@keyframes zoomIn {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoomOut {
    0% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}

.zoom-in {
    animation: zoomIn 2s infinite;
    width: 99px;
}

.zoom-out {
    animation: zoomOut 2s infinite;
}

.modal-img {
    height: 300px;
    width: 300px;
    animation: 4s move infinite ease-in-out;
}

@keyframes move {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    30% {
        clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
    }

    60% {
        clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
    }

    80% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 15px;
    text-align: center;
    z-index: 1000;
}

.cookie-banner p {
    display: inline;
    margin-right: 15px;
}

.accept-button,
.reject-button {
    background-color: #2387C0;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
}

.reject-button {
    background-color: #d9534f;
}

.accept-button:hover,
.reject-button:hover {
    opacity: 0.9;
}

.list-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.icon {
    color: green;
    margin-right: 8px;
}

.list-style {
    list-style-type: none;
    padding-left: 0;
}

.text-left {
    text-align: left;
}

.breadcrumb-text {
    font-family: 'Montserrat';
    font-size: 0.8rem;
    color: #767676;
    font-weight: 500;
    margin-top: 1rem;
}

.breadcrumb-title {
    margin-top: 0.6rem;
    font-family: 'Montserrat';
    font-size: 2rem;
    color: #292D32;
    font-weight: 600;
}

.product-category-div {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin-bottom: 0.8rem;
}

.product-category-div-item {
    padding: 0.6rem 1.2rem;
    border: 1px solid #767676;
    border-radius: 5px;
    margin-right: 1rem;
    margin-top: 0.8rem;
}

.product-category-div-item:hover {
    padding: 0.6rem 1.2rem;
    background: #767676;
    border-radius: 5px;
    margin-right: 1rem;
    margin-top: 0.8rem;
}

.product-category-div-item-active {
    padding: 0.6rem 1.2rem;
    background: #0179D4;
    border-radius: 5px;
    margin-right: 1rem;
    margin-top: 0.8rem;
}

.product-category-div-item:hover button {
    color: #fff !important;
}

.product-category-div-item-active button {
    color: #fff !important;
}

.products-img-name-card {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.products-img-name-card-img {
    background-color: #f9f9f9;
    padding: 1.3rem;
    border-radius: 8px;
    overflow: hidden;
}

.products-img-name-card-name {
    padding-top: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.products-img-name-card-name p {
    font-weight: 500;
    font-size: 0.9rem;
    font-family: 'Montserrat';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.copyright {
    border-top: 1px solid #ffffff;
    padding: 10px;
}

.copyrighttext {
    color: #292D32;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 500;
    line-height: 28px;
}

.logo-container {
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0.625rem 1.6875rem;
    margin-top: -0.5rem;
}

.uploadResumeModal::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.42);
    z-index: 1;
}

.uploadResumeModal .modal-dialog {
    position: relative;
    z-index: 10;
    width: 670px;
}

.uploadResumeModal .modal-header {
    border-bottom: 0;
    padding: 0.8rem 1.25rem 0;
}

.contacts-slide-in {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 2rem;
    color: '#000';
}

.catalogue-text {
    font-family: 'Montserrat';
    font-weight: 500 !important;
    font-size: 0.9375rem !important;
    color: #000 !important;
    text-align: left;
}

.catalogue-box {
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
}

.catalogue-img {
    border: 1px solid #EDEDED;
    border-radius: 8px;
    padding: 0.5rem;
}

.catalogue-img .card-img-top {
    height: 200px;
}

.custom-policy-tab {
    font-size: 1.1rem !important;
    padding: 0.95rem !important;
    color: #292D32 !important;
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
}

.nav-tabs .nav-link {
    border: none !important;
    border-bottom: 2px solid transparent;
}

.nav-tabs .nav-link.active {
    border-bottom: 2px solid #197CBA !important;
    color: #197CBA !important;
    font-weight: 700 !important;
}

.nav-tabs .nav-link:hover {
    border-bottom: 2px solid #197CBA;
}